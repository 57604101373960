import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const SignUp = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    country: ''
  });

  const getLocation = async () => {
    try {
      const res = await fetch('https://api.db-ip.com/v2/free/self');
      const { countryCode, error } = await res.json();
      if (error) throw new Error(error);
      return countryCode;
    } catch (error) {
      console.error('error getting location from api.db-ip.com:', error.message);
    }
  };

  function handleInputChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value
      };
    });
  };

  
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const country = await getLocation();
      setFormData({
        ...formData,
        country
      });
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            name: formData.name,
            country: formData.country,
            phone: formData.phone, 
          }
        }
      });

      if (error) throw error;
      
      alert('Revise su correo electrónico para confirmar su cuenta');

      navigate('/');
      
    } catch (error) {
      console.error('Error signing up:', error.message);
    }
  }

  return (
    <div style={styles.container}>
      <img src="logo.png" alt="Medify AI Logo" style={styles.logo} />
      <h2 style={styles.subtitle}>Registro de nuevo usuario</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          name="name"
          placeholder="Nombre y Apellido"
          value={formData.name}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Correo Electrónico"
          value={formData.email}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Contraseña"
          value={formData.password}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="tel"
          name="phone"
          placeholder="Teléfono"
          value={formData.phone}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Registrar</button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7'
  },
  logo: {
    width: '150px',
    marginBottom: '20px'
  },
  subtitle: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '20px'
  },
  form: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '16px'
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#007BFF',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer'
  }
};

export default SignUp;
