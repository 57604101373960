import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  SimpleGrid,
  useColorModeValue,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  FaMicrophone,
  FaFileMedical,
  FaClipboard,
  FaChartBar,
  FaUserCircle,
  FaInfoCircle,
  FaDatabase,
} from "react-icons/fa";

const Dashboard = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { specialty } = useParams();
  const isFertility = specialty === "fertilidad";

  const doctorName = token?.user?.user_metadata?.name || "Doctor";

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const navItems = [
    { icon: FaUserCircle, text: "Perfil Médico", path: "/profile" },
    // { icon: FaInfoCircle, text: "Sobre Medify", path: "/about" },
    { icon: FaDatabase, text: "Tus documentos generados", path: "/my-documents" },
  ];

  const features = [
    {
      icon: FaMicrophone,
      title: "Voice to Text para Consultas",
      description: "Transcribe consultas fácilmente.",
      path: `/voice-to-text/${specialty}`,
      bgColor: "blue.500",
    },
    {
      icon: FaFileMedical,
      title: "Resúmenes de Historia Clínica",
      description: "Accede y resume historias clínicas.",
      path: `/clinical-history-summary/${specialty}`,
      bgColor: "cyan.500",
    },
    {
      icon: FaClipboard,
      title: "Resúmenes de Primera Visita",
      description: "Crea resúmenes de primeras visitas.",
      path: `/first-visit-summary/${specialty}`,
      bgColor: "gray.100",
      color: "blue.500",
    },
    ...(isFertility
      ? [
          {
            icon: FaChartBar,
            title: "Módulo Estadístico",
            description: "Visualiza estadísticas de pacientes.",
            path: "/statistical-module",
            bgColor: "white",
            color: "cyan.500",
          },
        ]
      : []),
  ];

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box
        w={{ base: "100%", md: "25%" }}
        p={5}
        bg={bgColor}
        borderRight="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box
            bg="gray.100"
            p={5}
            borderRadius="md"
            boxShadow="md"
          >
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Bienvenido a Medify AI
            </Heading>
            <Text fontSize="sm">
              Gestiona tus tareas diarias de manera eficiente:
              <br /><br />
              1. Explora todas nuestras funcionalidades y eficientiza tu consultorio.
              <br /><br />
              2. Accede a tu perfil médico y configuralo de ser necesario.
              {/* <br /><br />
              3. Conoce más sobre Medify. */}
              <br /><br />
              3. Accede a tus documentos médicos generados.
            </Text>
          </Box>
          <VStack as="nav" spacing={3}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                leftIcon={<Icon as={item.icon} />}
                onClick={() => navigate(item.path)}
                w="full"
                justifyContent="flex-start"
                variant="ghost"
              >
                {item.text}
              </Button>
            ))}
          </VStack>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Box textAlign="center">
            <Heading as="h1" size="xl" mb={2}>
              Bienvenido/a, Dr./Dra. {doctorName} 👋
            </Heading>
            <Text fontSize="xl" fontWeight="semibold">
              Optimizá tu consultorio con Inteligencia Artificial
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {features.map((feature, index) => (
              <Box
                key={index}
                bg={feature.bgColor}
                color={feature.color || "white"}
                p={5}
                borderRadius="md"
                boxShadow="md"
                cursor="pointer"
                onClick={() => navigate(feature.path)}
                transition="all 0.3s"
                _hover={{ transform: "translateY(-5px)", boxShadow: "lg" }}
              >
                <Icon as={feature.icon} boxSize={12} mb={3} />
                <Heading as="h3" size="md" mb={2}>
                  {feature.title}
                </Heading>
                <Text>{feature.description}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Dashboard;