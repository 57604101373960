import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  SignUp,
  SignIn,
  Homepage,
  Dashboard,
  VoiceToText,
  ClinicalHistorySummaries,
  FirstVisitSummaries,
  StatisticalModule,
  UserProfile,
  MyDocuments,
  Form,
} from './pages';
import DocumentationPage from './DocumentationPage';
import { AuthProvider, useAuth } from './components/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  return token ? children : <Navigate to="/" replace />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login/:specialty" element={<SignIn />} />
          <Route
            path="/dashboard/:specialty"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/voice-to-text/:specialty"
            element={
              <ProtectedRoute>
                <VoiceToText />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clinical-history-summary/:specialty"
            element={<ClinicalHistorySummaries />}
          />
          <Route
            path="/first-visit-summary/:specialty"
            element={
              <ProtectedRoute>
                <FirstVisitSummaries />
              </ProtectedRoute>
            }
          />
          <Route path="/statistical-module" element={<StatisticalModule />} />
          <Route path="/form" element={<Form />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-documents"
            element={
              <ProtectedRoute>
                <MyDocuments />
              </ProtectedRoute>
            }
          />
          <Route path="/documentation" element={<DocumentationPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
