// src/SignIn.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../supabaseClient';
import { useAuth } from '../components/AuthContext'; // Import the useAuth hook

const SignIn = () => {

  const { login } = useAuth(); // Use the login function from AuthContext
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loginType, setLoginType] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login/fertilidad') {
      setLoginType('fertilidad');
    } else if (location.pathname === '/login/ginecologia') {
      setLoginType('ginecologia');
    }
  }, [location]);

  function handleInputChange(event) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });

      if (error) throw error;

      // Use the login function from AuthContext to set the token
      login(data);

      // Navigate to the appropriate dashboard
      navigate(loginType === 'fertilidad' ? '/dashboard/fertilidad' : '/dashboard/ginecologia');

    } catch (error) {
      console.log(error.error_description || error.message);
      alert('Error de inicio de sesión');
    }
  };

  const getImageSource = () => {
    return loginType === 'fertilidad' 
      ? `${process.env.PUBLIC_URL}/invitro.jpeg` 
      : `${process.env.PUBLIC_URL}/images.jpg`;
  };

  const getSubtitle = () => {
    return loginType === 'fertilidad'
      ? 'Asistente IA para especialistas en fertilidad'
      : 'Asistente IA para especialistas en ginecología';
  };

  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img src={getImageSource()} alt={loginType === 'fertilidad' ? "In Vitro Procedure" : "Gineco"} style={styles.image} />
      </div>
      <div style={styles.formContainer}>
        <div style={styles.logoContainer}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" style={styles.logo} />
          <h3 style={styles.subtitle}>{getSubtitle()}</h3>
        </div>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.title}>Iniciar Sesión</h2>
          <div style={styles.inputContainer}>
            <FontAwesomeIcon icon={faUser} style={styles.icon} />
            <input
              placeholder="Mail de usuario"
              name="email"
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputContainer}>
            <FontAwesomeIcon icon={faLock} style={styles.icon} />
            <input
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={handleInputChange}
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>Entrar</button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    backgroundColor: '#f7f7f7', // Fondo general
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f7f7', // Fondo ligeramente más oscuro para la imagen
    padding: '20px',
    overflow: 'hidden', 
    height: '100%', 
  },
  image: {
    width: '70%', // Ajusta este valor según necesites
    height: 'auto',
    borderRadius: '10px', // Bordes más suaves
    objectFit: 'contain',
    backgroundColor: '#ffffff', // Fondo blanco detrás de la imagen para resaltar
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f7f7',
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '300px',
    height: 'auto',
    marginBottom: '0px',
  },
  subtitle: {
    fontSize: '24px',
    color: '#2F2F2F',
    marginBottom: '30px',
  },
  form: {
    width: '500px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#F5F5F5',
    borderRadius: '5px',
  },
  icon: {
    marginRight: '10px',
    color: '#0058A3',
  },
  input: {
    flex: 1,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#ffffff',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '20px',
  },
};

export default SignIn;