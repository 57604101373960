import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Textarea,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaSync, FaUserCircle, FaDatabase } from 'react-icons/fa';

const DocumentStatus = {
  READY: 'Resumen generado',
  PENDING: 'Resumen pendiente',
};

const DocumentType = {
  COMPLEX_SUMMARY: "Resumen Historia Clinica",
  FIRST_VISIT: "Resumen de Primera Visita",
  TRANSCRIPT_SUMMARY: "Resumen Consulta",
};

const MyDocuments = () => {
  const { token } = useAuth();
  const { specialty } = useParams();
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSummary, setEditedSummary] = useState('');
  const [editedPatientName, setEditedPatientName] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const fetchDocuments = async () => {
    if (!token?.user?.email) return;

    const email = encodeURIComponent(token.user.email);
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;
    const url = `${db_mongo_url_api}/documents/search/${email}?limit=20&offset=0`;

    const fetchPromise = axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(response => {
        setDocuments(response.data);
        return response.data;
      });

    toast.promise(fetchPromise, {
      success: {
        title: 'Documentos cargados',
        description: 'Los documentos se han cargado exitosamente.',
        position: 'top'
      },
      error: {
        title: 'Error al cargar',
        description: 'No se pudieron cargar los documentos. Por favor, intente de nuevo.',
        position: 'top'
      },
      loading: {
        title: 'Cargando documentos',
        description: 'Por favor, espere mientras se cargan los documentos.',
        position: 'top'
      },
    });

    try {
      await fetchPromise;
    } catch (error) {
      console.error('Error fetching documents:', error.message);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [token]);

  const handleRefresh = () => {
    fetchDocuments();
  };

  const handleViewDocument = document => {
    setSelectedDocument({ ...document });
    setEditedSummary(document.summary || '');
    setEditedPatientName(document.patient_name || '');
    setIsEditing(false);
    onOpen();
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedSummary(selectedDocument?.summary || '');
      setEditedPatientName(selectedDocument?.patient_name || '');
    }
  };

  const handleSummaryChange = event => {
    setEditedSummary(event.target.value);
  };

  const handlePatientNameChange = (event) => {
    setEditedPatientName(event.target.value);
  };

  const handleSaveChanges = async () => {
    const db_mongo_url_api = process.env.REACT_APP_DB_MONGO_API;

    const url = `${db_mongo_url_api}/documents/?id=${selectedDocument.id}`;

    const updateData = {
      patient_name: editedPatientName,
      summary: editedSummary
    };

    const updatePromise = axios.put(url, updateData, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }).then(response => {
      setSelectedDocument(prev => ({ ...prev, patient_name: editedPatientName, summary: editedSummary }));
      setIsEditing(false);
      // Update the document in the documents list
      setDocuments(prevDocuments => 
        prevDocuments.map(doc => 
          doc.id === selectedDocument.id 
            ? { ...doc, patient_name: editedPatientName, summary: editedSummary } 
            : doc
        )
      );
      return response.data;
    });

    toast.promise(updatePromise, {
      success: { 
        title: 'Cambios guardados', 
        description: 'Los cambios se han guardado exitosamente en la base de datos.',
        position: 'top'
      },
      error: { 
        title: 'Error al guardar', 
        description: 'No se pudieron guardar los cambios. Por favor, intente de nuevo.',
        position: 'top'
      },
      loading: { 
        title: 'Guardando cambios', 
        description: 'Por favor, espere mientras se guardan los cambios.',
        position: 'top'
      },
    });

    try {
      await updatePromise;
    } catch (error) {
    }
  };

  const handleDownloadSummary = () => {
    toast({
      title: 'Resumen descargado',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const getDocumentTypeLabel = (type) => {
    switch (type) {
      case "complex_summary":
        return DocumentType.COMPLEX_SUMMARY;
      case "first_visit":
        return DocumentType.FIRST_VISIT;
      case "transcript_summary":
        return DocumentType.TRANSCRIPT_SUMMARY;
      default:
        return type;
    }
  };

  const getDocumentStatusLabel = status => {
    switch (status) {
      case 'ready':
        return DocumentStatus.READY;
      case 'pending':
        return DocumentStatus.PENDING;
      default:
        return status;
    }
  };

  const markdownComponents = {
    ...ChakraUIRenderer(),
    p: props => <Text fontSize="sm" fontFamily="body" mb={2} {...props} />,
    h1: props => (
      <Text
        fontSize="xl"
        fontWeight="bold"
        fontFamily="heading"
        mb={3}
        {...props}
      />
    ),
    h2: props => (
      <Text
        fontSize="lg"
        fontWeight="semibold"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    h3: props => (
      <Text
        fontSize="md"
        fontWeight="medium"
        fontFamily="heading"
        mb={2}
        {...props}
      />
    ),
    ul: props => (
      <Box as="ul" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    ol: props => (
      <Box as="ol" pl={4} fontSize="sm" fontFamily="body" mb={2} {...props} />
    ),
    li: props => (
      <Box as="li" fontSize="sm" fontFamily="body" mb={1} {...props} />
    ),
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box
        w={{ base: "100%", md: "25%" }}
        p={5}
        bg={bgColor}
        borderRight="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box
            bg="gray.100"
            p={5}
            borderRadius="md"
            boxShadow="md"
          >
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Mis Documentos
            </Heading>
            <Text fontSize="sm">
              1. En este sector encontrarás los resúmenes de tus consultas.
              <br /><br />
              2. Podrás encontrar los mismos por tipo de documento.
              <br /><br />
              3. Aguarda a que el Estado del documento sea "generado" para poder
              acceder al mismo.
            </Text>
          </Box>
          <Button
            leftIcon={<Icon as={FaSync} />}
            onClick={handleRefresh}
            w="full"
          >
            Actualizar Documentos
          </Button>
        </VStack>
      </Box>

      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl" textAlign="center">
            Mis Documentos
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {documents.map(document => (
              <Card key={document.id} variant="outline">
                <CardHeader>
                  <Heading size="md">
                    {document.patient_name || 'Nombre no disponible'}
                  </Heading>
                </CardHeader>
                <CardBody>
                  <Stack spacing={3}>
                    <Text fontSize="sm" color="gray.600">
                      Creado: {new Date(document.created).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Tipo:</strong> {getDocumentTypeLabel(document.type_document)}
                    </Text>
                    <Text
                      color={
                        document.state === 'ready'
                          ? 'green.500'
                          : document.state === 'pending'
                          ? 'orange.500'
                          : 'gray.500'
                      }
                      fontWeight="bold"
                    >
                      Estado: {getDocumentStatusLabel(document.state)}
                    </Text>
                  </Stack>
                </CardBody>
                <CardFooter>
                  {document.state === 'ready' ? (
                    <Button
                      colorScheme="blue"
                      onClick={() => handleViewDocument(document)}
                    >
                      Visualizar Documento
                    </Button>
                  ) : (
                    <Button isDisabled>Pendiente</Button>
                  )}
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </VStack>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Detalles del Documento</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Text fontWeight="bold">Nombre del Paciente:</Text>
              {isEditing ? (
                <Input
                  value={editedPatientName}
                  onChange={handlePatientNameChange}
                  placeholder="Nombre del paciente"
                />
              ) : (
                <Text>{selectedDocument?.patient_name || 'No disponible'}</Text>
              )}

              <Text fontWeight="bold">Resumen:</Text>
              {isEditing ? (
                <Textarea
                  value={editedSummary}
                  onChange={handleSummaryChange}
                  minHeight="550px"
                  fontSize="sm"
                  fontFamily="body"
                />
              ) : (
                <Box
                  border="0px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                  fontSize="sm"
                  fontFamily="body"
                  maxHeight="550px"
                  overflowY="auto"
                >
                  <ReactMarkdown
                    components={markdownComponents}
                    children={selectedDocument?.summary || ''}
                    skipHtml
                  />
                </Box>
              )}

              <Text fontWeight="bold">Fecha de Creación:</Text>
              <Text fontSize="sm">
                {new Date(selectedDocument?.created).toLocaleString()}
              </Text>

              <Button
                onClick={handleEditToggle}
                colorScheme={isEditing ? 'red' : 'blue'}
              >
                {isEditing ? 'Cancelar Edición' : 'Editar Documento'}
              </Button>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleDownloadSummary}>
              Descargar Resumen
            </Button>
            {isEditing && (
              <Button colorScheme="green" onClick={handleSaveChanges}>
                Guardar Cambios
              </Button>
            )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
    </Flex>
  );
};

export default MyDocuments;
