import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Icon,
  Input,
  List,
  ListItem,
  IconButton,
  useToast,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { FaFileUpload, FaPaperPlane, FaSpinner, FaTimes } from 'react-icons/fa';

const ClinicalHistorySummaries = () => {
  const { token } = useAuth();
  const [archivosCargados, setArchivosCargados] = useState([]);
  const [cargando, setCargando] = useState(false);
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const manejarCargaArchivos = (evento) => {
    const archivos = Array.from(evento.target.files);
    const nuevosArchivos = archivos.filter(archivo => 
      !archivosCargados.some(archivoExistente => archivoExistente.name === archivo.name)
    );

    if (nuevosArchivos.length + archivosCargados.length > 3) {
      toast({
        title: "Límite de archivos alcanzado",
        description: "Puede cargar un máximo de 3 archivos.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setArchivosCargados(prevArchivos => [...prevArchivos, ...nuevosArchivos]);
  };

  const eliminarArchivo = (index) => {
    setArchivosCargados(prevArchivos => prevArchivos.filter((_, i) => i !== index));
  };

  const enviarDocumentos = async () => {
    if (archivosCargados.length === 0) {
      toast({
        title: "No hay archivos",
        description: "Por favor, cargue al menos un archivo antes de enviar.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setCargando(true);
    const formData = new FormData();
    archivosCargados.forEach((archivo) => {
      formData.append('files', archivo);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SUMMARIZATION_URL}/complex_summary/upload-files`,
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            doctor_email: token?.user?.email || 'default@email.com',
            doctor_id: token?.user?.id || 'default_id',
            type_document: 'complex_summary'
          }
        }
      );

      console.log('Respuesta del servidor:', response.data);
      toast({
        title: "Éxito",
        description: "Archivos enviados con éxito. El resumen se generará en breve.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setArchivosCargados([]);
    } catch (error) {
      console.error('Error al subir archivos:', error);
      toast({
        title: "Error",
        description: "Hubo un problema al enviar los archivos. Por favor, intente de nuevo.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setCargando(false);
    }
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box w={{ base: "100%", md: "25%" }} p={5} bg={bgColor} borderRight="1px solid" borderColor="gray.200">
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Instrucciones para Resúmenes
            </Heading>
            <Text fontSize="sm">
              1. Adjunte documentos (DOCX, PDF, TXT).
              <br /><br />
              2. Cargue hasta 3 archivos.
              <br /><br />
              3. Haga clic en 'Enviar Documentos'.
              <br /><br />
              4. El resumen generado estará disponible en breve.
            </Text>
          </Box>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl" textAlign="center">
            Cargar Documentos Médicos
          </Heading>

          <Card bg={cardBgColor} shadow="md" borderRadius="lg">
            <CardBody>
              <VStack spacing={6}>
                <Button
                  as="label"
                  leftIcon={<Icon as={FaFileUpload} />}
                  colorScheme="blue"
                  size="lg"
                  cursor="pointer"
                  htmlFor="file-upload"
                >
                  Seleccionar Archivos
                </Button>
                <Input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={manejarCargaArchivos}
                  display="none"
                  accept=".doc,.docx,.pdf,.txt"
                />

                <List spacing={3} width="100%">
                  {archivosCargados.map((archivo, index) => (
                    <ListItem key={index} p={2} bg="gray.100" borderRadius="md" display="flex" justifyContent="space-between" alignItems="center">
                      <Text>{archivo.name}</Text>
                      <IconButton
                        icon={<FaTimes />}
                        onClick={() => eliminarArchivo(index)}
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                      />
                    </ListItem>
                  ))}
                </List>

                <Button
                  leftIcon={cargando ? <Icon as={FaSpinner} spin /> : <Icon as={FaPaperPlane} />}
                  onClick={enviarDocumentos}
                  isLoading={cargando}
                  loadingText="Procesando..."
                  colorScheme="blue"
                  size="lg"
                  width="100%"
                >
                  Enviar Documentos
                </Button>
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Box>
    </Flex>
  );
};

export default ClinicalHistorySummaries;