import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    const storedToken = sessionStorage.getItem('token');
    return storedToken ? JSON.parse(storedToken) : null;
  });

  const login = useCallback((newToken) => {
    setToken(newToken);
    sessionStorage.setItem('token', JSON.stringify(newToken));
  }, []);

  const logout = useCallback(async () => {
    setToken(null);
    sessionStorage.removeItem('token');
    await supabase.auth.signOut();
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = sessionStorage.getItem('token');
      if (!storedToken && token) {
        logout();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [token, logout]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('token');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);