import React, { useState } from "react";
import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  Select,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useColorModeValue,
  FormControl,
  FormLabel,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { FaCalculator } from "react-icons/fa";

// Constants (keep as is)
const BACKEND_URL = `${process.env.REACT_APP_DB_MONGO_API}/statistics/`;

const MODULE_TYPES = {
  FIV: { display: "FIV", backend: "fiv" },
  OVODONACION: { display: "Ovodonación", backend: "ovodonacion" },
  PRESERVACION: { display: "Preservación", backend: "preservacion" },
};

const AGE_OPTIONS = [
  { value: "≤ 35 años", label: "Menor a 36 años" },
  { value: "36-38 años", label: "Entre 36 y 38 años" },
  { value: "39-42 años", label: "Entre 39 y 42 años" },
  { value: "≥ 43 años", label: "Mayor a 42 años" },
];

const AMH_OPTIONS = [
  { value: "≤ 1", label: "Menor a 1" },
  { value: "1,01 1,5", label: "Entre 1 y 1.5" },
  { value: "1,51 2", label: "Entre 1.5 y 2" },
  { value: "≥ 2,01", label: "Mayor a 2" },
];

const RFA_OPTIONS = [
  { value: "< 5", label: "Menor a 5" },
  { value: "5 10", label: "Entre 5 y 10" },
  { value: "11 15", label: "Entre 11 y 15" },
  { value: "16 20", label: "Entre 16 y 20" },
  { value: "> 20", label: "Mayor a 20" },
];

const OVOCITOS_OPTIONS = [
  { value: "<=5", label: "Menor o igual a 5" },
  { value: "5 10", label: "Entre 5 y 10" },
];

const SEMEN_OPTIONS = [
  { value: "Normal", label: "Semen Normal" },
  { value: "Anormal", label: "Semen Anormal" },
];

// Reusable components
const CustomSelect = ({ label, value, options, onChange }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Select value={value} onChange={onChange}>
      <option value="">Seleccione</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </FormControl>
);

const CustomInput = ({ label, value, onChange, placeholder }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </FormControl>
);

const ResultItem = ({ label, value }) => (
  <Box>
    <Text fontWeight="bold">{label}:</Text>
    <Text>{value}</Text>
  </Box>
);

// Main component
const StatisticalModule = () => {
  const [moduleType, setModuleType] = useState(MODULE_TYPES.FIV.backend);
  const [patientInfo, setPatientInfo] = useState({
    age: "",
    amh: "",
    rfa: "",
    ovocitos: "",
  });
  const [partnerInfo, setPartnerInfo] = useState("Normal");
  const [results, setResults] = useState(null);
  const [error, setError] = useState("");

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const handleModuleChange = (newModuleType) => {
    setModuleType(newModuleType);
    setResults(null);
    setError("");
    setPatientInfo({
      age: "",
      amh: "",
      rfa: "",
      ovocitos: "",
    });
    setPartnerInfo("Normal");
  };

  const handlePatientInfoChange = (field, value) => {
    setPatientInfo((prev) => ({ ...prev, [field]: value }));
    setError("");
  };

  const validateInputs = () => {
    if (moduleType === MODULE_TYPES.FIV.backend) {
      return (
        patientInfo.age && patientInfo.amh && patientInfo.rfa && partnerInfo
      );
    } else if (moduleType === MODULE_TYPES.OVODONACION.backend) {
      return patientInfo.age && patientInfo.ovocitos && partnerInfo;
    } else if (moduleType === MODULE_TYPES.PRESERVACION.backend) {
      return patientInfo.age && patientInfo.amh && patientInfo.rfa;
    }
    return false;
  };

  const getOvodonacionResults = () => {
    const age = parseInt(patientInfo.age);
    const ovocitos = patientInfo.ovocitos;
    const semen = partnerInfo;

    if (age <= 55 && ovocitos === "<=5" && semen === "Normal") {
      return {
        blastos: "2-3",
        pgtNormalProbabilidad: "50-60%",
        tasaEmbarazoFresco: "50-60%",
        tasaEmbarazoCrioSinPgt: "40-50%",
        tasaEmbarazoCrioConPgt: "55-65%",
        tasaAbortoSinPgt: "15-20%",
        tasaAbortoConPgt: "5-10%",
        tasaNacidoVivo: "40-50%",
      };
    } else if (age <= 55 && ovocitos === "<=5" && semen === "Anormal") {
      return {
        blastos: "1-2",
        pgtNormalProbabilidad: "40-50%",
        tasaEmbarazoFresco: "40-50%",
        tasaEmbarazoCrioSinPgt: "30-40%",
        tasaEmbarazoCrioConPgt: "45-55%",
        tasaAbortoSinPgt: "20-25%",
        tasaAbortoConPgt: "10-15%",
        tasaNacidoVivo: "35-45%",
      };
    } else if (age <= 55 && ovocitos === "5 10" && semen === "Normal") {
      return {
        blastos: "4-6",
        pgtNormalProbabilidad: "60-70%",
        tasaEmbarazoFresco: "60-70%",
        tasaEmbarazoCrioSinPgt: "50-60%",
        tasaEmbarazoCrioConPgt: "65-75%",
        tasaAbortoSinPgt: "15-20%",
        tasaAbortoConPgt: "5-10%",
        tasaNacidoVivo: "50-60%",
      };
    } else if (age <= 55 && ovocitos === "5 10" && semen === "Anormal") {
      return {
        blastos: "2-4",
        pgtNormalProbabilidad: "50-60%",
        tasaEmbarazoFresco: "50-60%",
        tasaEmbarazoCrioSinPgt: "40-50%",
        tasaEmbarazoCrioConPgt: "55-65%",
        tasaAbortoSinPgt: "20-25%",
        tasaAbortoConPgt: "10-15%",
        tasaNacidoVivo: "45-55%",
      };
    }
    return null;
  };

  const handleCalculate = async () => {
    setError("");

    if (!validateInputs()) {
      setError("Por favor, complete todos los campos requeridos.");
      return;
    }

    if (moduleType === MODULE_TYPES.OVODONACION.backend) {
      const ovodonacionResults = getOvodonacionResults();
      setResults(ovodonacionResults);
      return;
    }

    const dataToSend = Object.entries({
      ...patientInfo,
      semen: moduleType === MODULE_TYPES.PRESERVACION.backend ? "Normal" : partnerInfo,
    }).reduce((acc, [key, value]) => {
      if (value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    dataToSend.dataframe = moduleType;

    try {
      const response = await fetch(BACKEND_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error al calcular:", error);
      setError(
        "Hubo un error al calcular los resultados. Por favor, intente de nuevo."
      );
    }
  };

  return (
    <Flex direction={{ base: "column", md: "row" }} minH="100vh" bg={bgColor}>
      {/* Left Section */}
      <Box w={{ base: "100%", md: "25%" }} p={5} bg={bgColor} borderRight="1px solid" borderColor="gray.200">
        <VStack spacing={6} align="stretch">
          <Image src={`${process.env.PUBLIC_URL}/logo.png`} alt="Medify AI Logo" maxW="220px" mx="auto" />
          <Box bg="gray.100" p={5} borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={3} textAlign="center">
              Guía para el Uso del Módulo Estadístico
            </Heading>
            <Text fontSize="sm">
              1. Completa los campos correspondientes de la paciente y pareja en
              el módulo FIV, Ovodonación o Preservación.
              <br /><br />
              2. Haz clic en "Calcular" para obtener el resultado de diferentes
              estadísticas que podrán ayudarte durante la consulta.
            </Text>
          </Box>
        </VStack>
      </Box>

      {/* Right Section */}
      <Box flex={1} p={5}>
        <VStack spacing={8} align="stretch">
          <Heading as="h1" size="xl" textAlign="center">
            Módulo Estadístico
          </Heading>
          
          <Box bg={cardBgColor} p={5} borderRadius="md" boxShadow="md">
            <VStack spacing={6} align="stretch">
              <RadioGroup onChange={handleModuleChange} value={moduleType}>
                <Stack direction="row" justify="center">
                  {Object.values(MODULE_TYPES).map((type) => (
                    <Radio key={type.backend} value={type.backend}>
                      {type.display}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>

              {moduleType === MODULE_TYPES.FIV.backend && (
                <Box>
                  <Heading size="md" mb={4}>Información Clínica de la Paciente</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <CustomSelect
                      label="Grupo de Edad"
                      value={patientInfo.age}
                      options={AGE_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("age", e.target.value)}
                    />
                    <CustomSelect
                      label="Grupo AMH"
                      value={patientInfo.amh}
                      options={AMH_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("amh", e.target.value)}
                    />
                    <CustomSelect
                      label="Grupo RFA"
                      value={patientInfo.rfa}
                      options={RFA_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("rfa", e.target.value)}
                    />
                  </SimpleGrid>
                </Box>
              )}

              {moduleType === MODULE_TYPES.OVODONACION.backend && (
                <Box>
                  <Heading size="md" mb={4}>Información Clínica de la Receptora</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <CustomInput
                      label="Edad Receptora"
                      value={patientInfo.age}
                      onChange={(e) => handlePatientInfoChange("age", e.target.value)}
                      placeholder="Edad Receptora"
                    />
                    <CustomSelect
                      label="Ovocitos a Recibir"
                      value={patientInfo.ovocitos}
                      options={OVOCITOS_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("ovocitos", e.target.value)}
                    />
                  </SimpleGrid>
                </Box>
              )}

              {moduleType === MODULE_TYPES.PRESERVACION.backend && (
                <Box>
                  <Heading size="md" mb={4}>Información Clínica de la Paciente</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <CustomSelect
                      label="Grupo de Edad"
                      value={patientInfo.age}
                      options={AGE_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("age", e.target.value)}
                    />
                    <CustomSelect
                      label="Grupo AMH"
                      value={patientInfo.amh}
                      options={AMH_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("amh", e.target.value)}
                    />
                    <CustomSelect
                      label="Grupo RFA"
                      value={patientInfo.rfa}
                      options={RFA_OPTIONS}
                      onChange={(e) => handlePatientInfoChange("rfa", e.target.value)}
                    />
                  </SimpleGrid>
                </Box>
              )}

              {moduleType !== MODULE_TYPES.PRESERVACION.backend && (
                <Box>
                  <Heading size="md" mb={4}>Información Clínica de la Pareja</Heading>
                  <CustomSelect
                    label="Semen"
                    value={partnerInfo}
                    options={SEMEN_OPTIONS}
                    onChange={(e) => setPartnerInfo(e.target.value)}
                  />
                </Box>
              )}

              {error && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle mr={2}>Error!</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <Button
                leftIcon={<FaCalculator />}
                colorScheme="blue"
                onClick={handleCalculate}
                size="lg"
                width="full"
              >
                {moduleType === MODULE_TYPES.PRESERVACION.backend
                  ? "Calcular Ovocitos Maduros"
                  : "Calcular la Tasa de Embarazo"}
              </Button>

              {results && (
                <Box borderTop="1px" borderColor="gray.200" pt={4}>
                  <Heading size="md" mb={4}>Resultados</Heading>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    {moduleType !== MODULE_TYPES.OVODONACION && (
                      <ResultItem
                        label="Ovocitos Maduros"
                        value={results.ovocitosMaduros}
                      />
                    )}
                    <ResultItem label="Blastos" value={results.blastos} />
                    <ResultItem
                      label="Probabilidad de PGT Normal"
                      value={results.pgtNormalProbabilidad}
                    />
                    <ResultItem
                      label="Tasa de Embarazo en Transferencia en Fresco"
                      value={results.tasaEmbarazoFresco}
                    />
                    <ResultItem
                      label="Tasa de Embarazo en Criotransferencia sin PGT"
                      value={results.tasaEmbarazoCrioSinPgt}
                    />
                    <ResultItem
                      label="Tasa de Embarazo en Criotransferencia con PGT"
                      value={results.tasaEmbarazoCrioConPgt}
                    />
                    <ResultItem
                      label="Tasa de Aborto sin PGT"
                      value={results.tasaAbortoSinPgt}
                    />
                    <ResultItem
                      label="Tasa de Aborto con PGT"
                      value={results.tasaAbortoConPgt}
                    />
                    <ResultItem
                      label="Tasa de Nacido Vivo"
                      value={results.tasaNacidoVivo}
                    />
                  </SimpleGrid>
                </Box>
              )}
            </VStack>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
};

export default StatisticalModule;